import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { Modal, Input, Space, Button, Table, DatePicker, Spin } from "antd";
import {
  FilePdfOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import moment from "moment";

import Highlighter from "react-highlight-words";

import { getRobotReports } from "./reportSlice";
import { updateRobotUsage } from "../robot/robotSlice";

import LoadingPage from "../../components/LoadingPage";

import { fileSizeToString } from "../../libs/utilsTyped";

import PreviewPDF from "./PreviewPDF";

import { pdfjs } from "react-pdf";
import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import { useLoadingWithProgress } from "../../libs/useLoading";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RangePicker = DatePicker.RangePicker;

const rangePresets = {
  "Last 7 Days": [moment().add(-7, "days"), moment()],
  "Last 30 Days": [moment().add(-30, "days"), moment()],
  "Last 90 Days": [moment().add(-90, "days"), moment()],
  "1 year": [moment().add(-1, "year"), moment()],
  "All time": [moment("2017-01-01"), moment()],
};

const ReportsList = (prop) => {
  const dispatch = useDispatch();
  const { robotName } = useParams();
  const robotId = robotName.match(/(\d+)/)[0];

  const [reports, loadingProgress] = useLoadingWithProgress(
    "reports",
    ({ reports }) => reports,
    getRobotReports,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [dateRange, setDateRange] = useState([
    moment().add(-30, "days"),
    moment(),
  ]);

  useEffect(() => {
    dispatch(
      getRobotReports({
        id: robotId,
        start: dateRange[0].format("YYYY-MM-DD"),
        end: dateRange[1].format("YYYY-MM-DD"),
      }),
    );
    // do not add dateRange, this should only run on first load
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, robotId]);

  if (!reports) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} />;
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleModalOpen = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
    handleUsageUpdate(robotId, { ...item, activity: "cs_preview" });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const handleDownload = (item) => {
    handleUsageUpdate(robotId, { ...item, activity: "cs_download" });
  };

  const handleUsageUpdate = (robotId, { name: fileName, size, activity }) => {
    dispatch(
      updateRobotUsage({
        id: robotId,
        payload: {
          size,
          fileName,
          activity,
        },
      }),
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "60%",
      sorter: {
        compare: (a, b) => {
          const aString = a.name.toLowerCase();
          const bString = b.name.toLowerCase();

          if (aString > bString) {
            return 1;
          }
          if (aString < bString) {
            return -1;
          }
          return 0;
        },
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Size",
      key: "size",
      width: "20%",
      render: (_, item) => <span>{fileSizeToString(item.size)}</span>,
    },
    {
      title: "Uploaded At",
      key: "date",
      width: "20%",
      sorter: {
        compare: (a, b) => {
          const aString = a.date;
          const bString = b.date;

          if (aString > bString) {
            return 1;
          }
          if (aString < bString) {
            return -1;
          }
          return 0;
        },
      },
      render: (_, item) => (
        <span>{moment(item.date).format("YYYY-MM-DD, HH:mm:ss")}</span>
      ),
    },
    {
      title: "View",
      dataIndex: "",
      key: "download",
      render: (_, item) => (
        <FilePdfOutlined
          onClick={() => {
            handleModalOpen(item);
          }}
        />
      ),
    },
    {
      title: "Download",
      dataIndex: "",
      key: "download",
      render: (_, item) => (
        <a
          onClick={() => handleDownload(item)}
          href={item.download}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadOutlined />
        </a>
      ),
    },
  ];
  const data = reports.map((item, index) => {
    return {
      key: index,
      name: item.fileName,
      size: item.size,
      date: item.uploadedAt,
      download: item.url,
    };
  });

  return (
    <>
      <div className="date-picker-listview">
        Select date range:
        <RangePicker
          value={dateRange}
          ranges={rangePresets}
          onChange={(dates) => {
            setDateRange(dates);
          }}
        />
        <button
          onClick={() => {
            if (!dateRange?.[0]) return;

            const start = dateRange[0].format("YYYY-MM-DD");
            const end = dateRange[1].format("YYYY-MM-DD");
            const isAllTime =
              rangePresets["All time"][0].format("YYYY-MM-DD") === start &&
              rangePresets["All time"][1].format("YYYY-MM-DD") === end;

            if (isAllTime) {
              dispatch(getRobotReports({ id: robotId }));
            } else {
              dispatch(
                getRobotReports({
                  id: robotId,
                  start: dateRange[0].format("YYYY-MM-DD"),
                  end: dateRange[1].format("YYYY-MM-DD"),
                }),
              );
            }
          }}
        >
          Load date range
        </button>
      </div>
      <Spin
        spinning={!loadingProgress.isFinished()}
        indicator={
          <LoadingPage
            size={LOADING_ANIMATION_SIZE_FULL}
            progress={loadingProgress}
          />
        }
      >
        <Table columns={columns} dataSource={data}></Table>
      </Spin>
      {modalOpen && !!selectedItem && (
        <Modal
          title={selectedItem.name}
          visible={modalOpen}
          centered
          bodyStyle={{ height: "80%" }}
          width={"70%"}
          onOk={handleModalClose}
          onCancel={handleModalClose}
        >
          <PreviewPDF item={selectedItem} />
        </Modal>
      )}
    </>
  );
};

export default ReportsList;
